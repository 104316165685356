import { createContext, useState } from "react";

export const PageContext = createContext({
    isHomepage: false,
    setIsHomepage: () => {},
    serviceWorker: null,
    setServiceWorker: () => {},
});

const PageContextProvider = (props) => {
    const [isHomepageLoaded, setIsHomepageLoaded] = useState(false);
    const [sw, setSw] = useState(null);

    const setHomepageLoaded = () => {
        setIsHomepageLoaded(true);
    }

    const setServiceworker = (serviceworker) => {
        setSw(serviceworker)
    }

    return (
        <PageContext.Provider value={{ isHomepage: isHomepageLoaded, setIsHomepage: setHomepageLoaded, serviceWorker:sw, setServiceWorker: setServiceworker}}>
            {props.children}
        </PageContext.Provider>
    )
}

export default PageContextProvider;