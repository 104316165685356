import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import lowerCircles from "../asstes/icons/lower-circles.svg";
import configdata from "../config.js";
import { PageContext } from "../context/PageContext";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";

const Home = () => {
  const [asstes, setasstes] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const ctx = useContext(PageContext);

  useEffect(() => {
    ctx.setIsHomepage(true);
  }, []);


  // const setAssetsWithStorageIndex = (assetIndex) => {
  //   setasstes(assetIndex);
  // };

  const configPrompt = () => {
    const configIndex = window.prompt(
      "Írd be hányas számú Hangboxot szeretnéd konfigurálni"
    );
    if (configIndex >= 1 && configIndex <= 13) {
      localStorage.setItem("index", configIndex);
      setasstes(configdata[configIndex]);
    } else {
      configPrompt();
    }
  };

  useEffect(() => {
    const index = localStorage.getItem("index");
    if (index) {
      setasstes(configdata[index]);
    } else {
      configPrompt();
    }
  }, []);

  useEffect(() => {
    document.body.style.background = asstes.background;
  }, [asstes]);

  const navigateFromHome = (asset) => {
    if (asset.hasOwnProperty("subpoints")) {
      navigate("/audioselector", { state: asset });
    } else {
      navigate("/playback", {
        state: { asset: asset, path: location.pathname },
      });
    }
  };

  //console.log(asstes, "assets");

  return (
    <div className="home-view">
      {Object.keys(asstes).length !== 0 && (
        <>
          <div className="titles">
            {asstes.hu && (
              <h1
                className="main-title"
                onClick={() => {
                  navigateFromHome(asstes.hu);
                }}
              >
                {asstes.hu.title}
              </h1>
            )}

            <img src={lowerCircles} className="lower-circles"></img>

            {asstes.hu2 && (
              <h1
                className="main-title"
                onClick={() => {
                  navigateFromHome(asstes.hu2);
                }}
              >
                {asstes.hu2.title}
              </h1>
            )}

            {asstes.ro ? (
              <>
                <h1
                  className="main-title"
                  onClick={() => {
                    navigateFromHome(asstes.ro);
                  }}
                >
                  {asstes.ro.title}
                </h1>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
