import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import useSound from "use-sound";
import playIcon from "../asstes/icons/start.svg";
import pauseIcon from "../asstes/icons/pause.svg";
import replayIcon from "../asstes/icons/replay.svg";
import navIcon from "../asstes/icons/navigation-back.svg";
import upperCircles from "../asstes/icons/upper-circles.svg";
import lowerCircles from "../asstes/icons/lower-circles.svg";
import { PageContext } from "../context/PageContext";

const Playback = () => {
  const location = useLocation();
  const asstes = location.state;
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [play, { pause, stop, duration, sound }] = useSound(asstes.asset.sound);

  const ctx = useContext(PageContext);

  //console.log(asstes.asset, "asset");

  useEffect(() => {
    if (ctx.isHomepage === false) {
      navigate("/");
    }
  }, []);

  const [currTime, setCurrTime] = useState({
    min: "",
    sec: "",
  });

  const [seconds, setSeconds] = useState(0);

  let time;

  useEffect(() => {
    const sec = duration / 1000;
    const min = Math.floor(sec / 60);
    const secRemain = Math.floor(sec % 60);
    time = {
      min: min,
      sec: secRemain,
    };
  });

  useEffect(() => {
    startSound();

    const interval = setInterval(() => {
      if (sound) {
        setSeconds(sound.seek([])); // setting the seconds state with the current state
        const min = Math.floor(sound.seek([]) / 60);
        const sec = Math.floor(sound.seek([]) % 60);
        setCurrTime({
          min,
          sec,
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [sound]);

  // useEffect(() => {
  //   startSound();
  // }, [duration]);

  const startSound = () => {
    play();
    setIsPlaying(true);
  };

  const pauseSound = () => {
    pause();
    setIsPlaying(false);
  };

  const replay = () => {
    stop();
    play();
    //setIsPlaying(false);
  };

  const navigateToHomepage = () => {
    if (asstes.path === "/audioselector") {
      navigate("/audioselector", { state: asstes.allasstes });
      stop();
    } else {
      navigate("/");
      stop();
    }
  };

  //console.log(location, 'location in playback');

  window.addEventListener("hashchange", (e) => {
    stop();
  });

  return (
    <>
      {asstes ? (
        <div className="playback-view">
          {/* <img src={upperCircles} className="playback-upper-circles"></img> */}
          <img
            src={navIcon}
            className="nav-back"
            onClick={navigateToHomepage}
          ></img>
          <div className="playback-title-holder">
            <h1 className="title-playback">{asstes.asset.title}</h1>
            <p className="description">{asstes.asset.description}</p>
          </div>

          <div className="timeline-outer">
            {/* {seconds && (
          <>
            <input
              type="range"
              min="0"
              max={duration / 1000}
              default="0"
              value={seconds}
              className="timeline"
              onChange={(e) => {
                sound.seek([e.target.value]);
              }}
            />
          </>
        )} */}
            <input
              type="range"
              min="0"
              max={duration / 1000}
              default="0"
              value={seconds}
              className="timeline"
              onChange={(e) => {
                sound.seek([e.target.value]);
              }}
            />
          </div>

          <div>
            {!isPlaying ? (
              <>
                <img
                  src={playIcon}
                  className="soundicon"
                  onClick={startSound}
                ></img>
              </>
            ) : (
              <>
                <img
                  src={pauseIcon}
                  className="soundicon"
                  onClick={pauseSound}
                ></img>
                <img
                  src={replayIcon}
                  className="soundicon"
                  onClick={replay}
                ></img>
              </>
            )}
          </div>
          {/* <img src={lowerCircles} className="playback-lower-circles"></img> */}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Playback;
