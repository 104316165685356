import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import navIcon from "../asstes/icons/navigation-back.svg";
import { PageContext } from "../context/PageContext";

const AudioSelector = () => {
  const location = useLocation();
  const data = location.state;
  const subpoints = data && data.subpoints;
  const navigate = useNavigate();
  const ctx = useContext(PageContext);

  //console.log(data, "data");

  

  useEffect(() => {
    if(ctx.isHomepage === false){
      navigate("/")
    }
  }, [])

  const navigateToHomepage = () => {
    navigate("/");
  };

  const navigateToPlayback = (asset) => {
    navigate("/playback", {
      state: { asset: asset, path: location.pathname, allasstes: data },
    });
  };

  return (
    <>
    {data ?     <div className="selector-page">
      <img
        src={navIcon}
        className="nav-back"
        onClick={navigateToHomepage}
      ></img>

      <div className="selector-maintitle-holder">
        <h1 className="title" style={{ textAlign: "center" }}>
          {data.title}
        </h1>
        {data.description && <p className="description">{data.description}</p>}
      </div>
      <div className="selector-titles-holder">
        {subpoints && (
          <ul>
            {subpoints.map((item, index) => {
              return (
                <li key={index}>
                  <h1
                    key={index}
                    className="selector-title"
                    onClick={() => navigateToPlayback(item)}
                  >
                    {item.title}
                  </h1>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div> : <></>}
    </>
  );
};

export default AudioSelector;
