

const assets = {
    1: {
        "background": "#ba8559",
        "hu": {
            "title": "Válogatás Petőfi Sándor kedvenc zeneszerzőjétől, Liszt Ferenctől",
            "description": "",
            "sound": "./sounds/liszt.mp3",
        },
        "ro": {
            "title": "Selecție din Franz Liszt, compozitorul preferat al lui Sándor Petőfi",
            "description": "",
            "sound": "./sounds/liszt.mp3",
        }
    },
    2: {
        "background": "#ba8559",
        "hu": {
            "title": "Válogatás Szendrey Júlia kedvenc zeneszerzőjétől, Chopintől",
            "description": "",
            "sound": "./sounds/chopin.wav",
        },
        "ro": {
            "title": "Selecție din Chopin, compozitorul preferat al al Júliei Szendrey",
            "description": "",
            "sound": "./sounds/chopin.wav"
        }
    },
    3: {
        "background": "#cb8a73",
        "hu": {
            "title": "Petőfi Sándor: Gróf Teleky Sándorhoz",
            "description": "Kovács Krisztián előadásában",
            "sound": "./sounds/GrofTelekySandorhoz.mp3",
        },
        // "ro": {
        //     "title": "Sándor Petőfi: Contelui Sándor Teleky",
        //     "description": "în interpretarea lui Andrei Stan",
        //     "sound": ""
        // }
    },
    4: {
        "background": "#8d8d4e",
        "hu": {
            "title": "Petőfi Sándor: Amióta én megházasodtam…",
            "description": "Kovács Krisztián előadásában",
            "sound": "./sounds/AmiotaEnMeghazasodtam.mp3",
        },
        "ro": {
            "title": "Sándor Petőfi: De când m-am însurat…",
            "description": "în interpretarea lui Andrei Stan",
            "sound": "./sounds/De_cand_m-am_insurat.mp3"
        }
    },
    5: {
        "background": "#e1836f",
        "hu": {
            "title": "Úti levelek",
            "description": "Kovács Krisztián előadásában",
            "sound": "",
            "subpoints": [
                {
                    "title": "Úti levelek Kerényi Frigyeshez, 17. levél, 1847. szeptember 15.",
                    "sound": "./sounds/Utilevelek17.mp3"
                },
                {
                    "title": "Úti levelek Kerényi Frigyeshez, 18. levél, 1847. október 14",
                    "sound": "./sounds/Utilevelek18.mp3"
                }
            ]
        },
        "ro": {
            "title": "Scrisori de călătorie",
            "description": "în interpretarea lui Andrei Stan",
            "sound": "",
            "subpoints": [
                {
                    "title": "Scrisori de călătorie către Frigyes Kerényi, scrisoarea 17, 15 septembrie 1847",
                    "sound": "./sounds/02 SP - Scrisori de calatorie - Andrei Stan.mp3"
                },
                {
                    "title": "Scrisori de călătorie către Frigyes Kerényi, scrisoarea 18, 14 octombrie 1847",
                    "sound": "./sounds/03 SP - Scrisori de calatorie - Andrei Stan.mp3"
                }
            ]
        }
    },
    6: {
        "background": "#66754f",
        "hu": {
            "title": "Szendrey Júlia koltói naplója",
            "description": "Mészáros Blanka előadásában",
            "sound": "",
            "subpoints": [
                {
                    "title": "1847.szeptember 22.",
                    "sound": "./sounds/szept22_zene.mp3"
                },
                {
                    "title": "1847.szeptember 28.",
                    "sound": "./sounds/szept28_zene.mp3"
                },
                {
                    "title": "1847.október 03.",
                    "sound": "./sounds/okt3_zene.mp3"
                },
                {
                    "title": "1847. október 12.",
                    "sound": "./sounds/okt12_zene.mp3"
                }
            ]
        },
        "ro": {
            "title": "Jurnalul de la Coltău al Júliei Szendrey",
            "description": "în interpretarea lui Ioana Stan",
            "sound": "",
            "subpoints": [
                {
                    "title": "22 septembrie 1847",
                    "sound": "./sounds/03 SZJ - Scrisorile lui Szendrei Julia - Ioana Stan.mp3"
                },
                {
                    "title": "28 septembrie 1847",
                    "sound": "./sounds/01 SZJ - Scrisorile lui Szendrei Julia - Ioana Stan.mp3"
                },
                {
                    "title": "3 octombrie 1847",
                    "sound": "./sounds/02 SZJ - Scrisorile lui Szendrei Julia - Ioana Stan.mp3"
                },
                {
                    "title": "12 octombrie 1847",
                    "sound": "./sounds/05 SZJ - Scrisorile lui Szendrei Julia - Ioana Stan.mp3"
                }
            ]
        }
    },
    7: {
        "background": "#696d60",
        "hu": {
            "title": "Válogatás Petőfi Sándor koltói verseiből",
            "description": "Kovács Krisztián előadásában",
            "sound": "",
            "subpoints": [
                {
                    "title": "Csendes élet",
                    "sound": "./sounds/Csendes_elet.mp3"
                },
                {
                    "title": "Egykor és most!",
                    "sound": "./sounds/Egykor_esmost.mp3"
                },
            ]
        },
        "ro": {
            "title": "Selecție din poeziile de la Coltău ale lui Sándor Petőfi",
            "description": "în interpretarea lui Andrei Stan",
            "sound": "",
            "subpoints": [
                {
                    "title": "Viața liniștită",
                    "sound": "./sounds/Viata_linistita.mp3"
                },
                {
                    "title": "Atunci și acum!",
                    "sound": "./sounds/Atunci_si_acum.mp3"
                },
            ]
        },
    },
    8: {
        "background": "#91766a",
        "hu": {
            "title": "Válogatás Petőfi Sándor koltói verseiből",
            "description": "Kovács Krisztián előadásában",
            "sound": "",
            "subpoints": [
                {
                    "title": "Elértem, amit ember érhet el",
                    "sound": "./sounds/Elertem_amitember_erhetel.mp3"
                },
                {
                    "title": "Az utolsó virágok",
                    "sound": "./sounds/Az_utolso_viragok.mp3"
                },
            ]
        },
        "ro": {
            "title": "Selecție din poeziile de la Coltău ale lui Sándor Petőfi",
            "description": "în interpretarea lui Andrei Stan",
            "sound": "",
            "subpoints": [
                {
                    "title": "Am obținut tot ce un om poate obține",
                    "sound": "./sounds/Am_obtinut_tot_ce_am_putut_obtine.mp3"
                },
                {
                    "title": "Ultimele flori",
                    "sound": "./sounds/Ultimele_flori.mp3"
                },
            ]
        },
    },
    9: {
        "background": "#a47965",
        "hu": {
            "title": "Petőfi Sándor: Tíz pár csókot egyvégbül…",
            "description": "Kovács Krisztián előadásában",
            "sound": "./sounds/Tizpar_csokot.mp3",
        },
        "ro": {
            "title": "Sándor Petőfi: Zece sărutări deodată…",
            "description": "în interpretarea lui Andrei Stan",
            "sound": "./sounds/Zece_sarutari_deodata.mp3"
        }
    },
    10: {
        "background": "#cd8e35",
        "hu": {
            "title": "Szeptember elején",
            "description": "Bereményi Géza dalszövege, Cseh Tamás előadásában",
            "sound": "./sounds/CsehTamas.mp3",
        },
    },
    11: {
        "background": "#cd8e36",
        "hu": {
            "title": "Szeptember végén",
            "description": "",
            "sound": "",
            "subpoints": [
                { "title": "Latinovits Zoltán előadásában", "sound": "../sounds/Latinovits.mp3" },
                { "title": "Cserhalmi György előadásában", "sound": "../sounds/Cserhalmi.mp3" },
                { "title": "Stohl András előadásában", "sound": "../sounds/Stohl.mp3" }
            ]
        },
        "ro": {
            "title": "Sfârșit de septembrie",
            "description": "în interpretarea lui Andrei Stan",
            "sound": "../sounds/Sfarsit_de_septembrie.mp3"
        }
    },
    12: {
        "background": "#cd8e36",
        "hu": {
            "title": "Petőfi Sándor: Szeptember végén feldolgozások",
            "description": "",
            "sound": "",
            "subpoints": [
                { "title": "Huszka Jenő szerzeménye Sárdy János előadásában", "sound": "../sounds/HuszkaJeno_SardyJanos.mp3" },
                { "title": " a Kávészünet előadásában", "sound": "../sounds/Kaveszunet.mp3" },
            ]
        },
    },
    13: {
        "background": "#cd8e36",
        "hu": {
            "title": "Petőfi Sándor: Szeptember végén",
            "description": "a Red Bull Pilvaker előadásában",
            "sound": "../sounds/RedBullPilvaker.mp3"
        },
        "hu2": {
            "title": "Szarka Gyula: Petőfi dicsérete",
            "description": "",
            "sound": "../sounds/SzarkaGyula.mp3"
        }
    }
}

export default assets;