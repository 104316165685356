import './App.css';
import { Routes, Route, HashRouter } from 'react-router-dom';
import routes from './routes/Routes';
import { useEffect } from 'react';
import PageContextProvider from './context/PageContext';

function App() {
  
  useEffect(() => {
    const handleTouchMove = (event) => {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    };

    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);


  useEffect(() => {
    const handleGestureStart = (e) => {
      e.preventDefault();
    };

    document.addEventListener('gesturestart', handleGestureStart);

    return () => {
      document.removeEventListener('gesturestart', handleGestureStart);
    };
  }, []);


  return (
    <PageContextProvider>
      <div className="app">
        <HashRouter>
          <Routes>
            {routes.map(({ path, element, exact }) => (
              <Route key={path} path={path} exact={exact} element={element} />
            ))}
          </Routes>
        </HashRouter>
      </div>
    </PageContextProvider>
  );
}

export default App;
